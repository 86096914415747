import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import Axios from "axios";
import { LanguageProvider } from "./context/LanguageContext";

const user = JSON.parse(localStorage.getItem("user"));
Axios.defaults.baseURL = "https://erptwo.asusapps.com";
Axios.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;

ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
