import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const token = user?.token;

const newRequest = axios.create({
  baseURL: "https://erptwo.asusapps.com/api/v1",
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});

export default newRequest;
